const isString = (subject) => typeof subject === "string";

const createElement = (type, attrs, className, data, win = null) => {
	win = win || self;
	const element = win.document.createElement(type);

	attrs = attrs || {};

	if (className) {
		attrs.class = className;
	}

	if (attrs) {
		Object.keys(attrs).forEach((key) => element.setAttribute(key, attrs[key]));
	}

	if (data) {
		Object.keys(data).forEach((key) => element.dataset[key] = data[key]);
	}

	return element;
};

const getElement = (selectorOrElement, win = null) => {
	win = win || self;

	return isString(selectorOrElement)
		? win.document.querySelector(selectorOrElement)
		: selectorOrElement;
};

const removeElement = (element) => {
	if (element.parentNode) {
		element.parentNode.removeChild(element);
	}
};

const appendElement = (element, parent) => {
	const actualParent = parent || document.body;
	(actualParent: any).appendChild(element);
};

const addStyles = (element, styles) => {
	Object.keys(styles).forEach((key: any) => {
		element.style[key] = styles[key];
	});
};

const showElement = (element) => {
	addStyles(element, { display: "block" });
};

const hideElement = (element) => {
	addStyles(element, { display: "none" });
};

export {
	createElement,
	getElement,
	removeElement,
	appendElement,
	addStyles,
	showElement,
	hideElement,
};
