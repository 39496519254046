export const UPLOAD_SOURCES = {
	LOCAL: "local",
	URL: "url",
	CAMERA: "camera",
	IMAGE_SEARCH: "image_search",
	GOOGLE_DRIVE: "google_drive",
	DROPBOX: "dropbox",
	FACEBOOK: "facebook",
	INSTAGRAM: "instagram",
	SHUTTERSTOCK: "shutterstock",
	GETTY: "getty",
	ISTOCK: "istock",
	UNSPLASH: "unsplash",
};

export const WIDGET_VIEW_TYPES = {
	INITIAL: "initial",
	EXPANDED: "expanded",
	MINI: "minimized",
};

export const INLINE_HEIGHT = 610;
